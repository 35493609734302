$(document).ready(function () {
    // $('#example1').rollbar({
    //     wheelSpeed: 50,             // content scroll speed on mouse wheel (0 to 100)
    //     touchSpeed: 1
    // });

    initSelectBox('#js-select-locations-mobile', '/api/countries/all');

    $('#js-update-basic-info').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-basic-info');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/info',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-edit-basic-info').modal('toggle');
            }
        });
    })

    $('#js-save-comment').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-comment');

        $.ajax({
            method: "POST",
            url: '/dashboard/influencers/' + id + '/comments',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-add-comment').modal('toggle');
            }
        });
    })

    $('#js-save-agency-comment').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-comment');

        $.ajax({
            method: "POST",
            url: '/agency/influencers/' + id + '/comments',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-add-comment').modal('toggle');
            }
        });
    })

    $('[data-js-delete-agency-comment]').on('click', function(evt) {
        evt.preventDefault();

        var influencer = $(this).data('influencer-id');
        var comment = $(this).data('comment-id');

        $.ajax({
            method: "GET",
            url: '/agency/influencers/' + influencer + '/comments/' + comment,
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
            }
        });
    });

    $('[data-js-delete-comment]').on('click', function(evt) {
        evt.preventDefault();

        var influencer = $(this).data('influencer-id');
        var comment = $(this).data('comment-id');

        $.ajax({
            method: "GET",
            url: '/dashboard/influencers/' + influencer + '/comments/' + comment,
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
            }
        });
    });

    $('#js-update-about').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-about');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/bio-info',
            data: form.serialize()
        }).done(function (data) {
            // if(data.success) {
                window.location.reload(false);
                $('#modal-edit-about').modal('toggle');
            // }
        });
    })

    $('#js-update-note').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-note');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/rate-info',
            data: form.serialize()
        }).done(function (data) {
            // if(data.success) {
                window.location.reload(false);
                $('#modal-edit-note').modal('toggle');
            // }
        });
    })

    $('#js-update-contact-details').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-contact-details');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/contact-info',
            data: form.serialize()
        }).done(function (data) {
            // if(data.success) {
                window.location.reload(false);
                $('#modal-edit-contact-details').modal('toggle');
            // }
        });
    })

    $('#js-update-manager-contact').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-manager-contact');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/manager-info',
            data: form.serialize()
        }).done(function (data) {
            // if(data.success) {
                window.location.reload(false);
                $('#modal-edit-manager-contact').modal('toggle');
            // }
        });
    })

    $('.js-delete-tag').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var tag = $(this).data('tag');

        $.ajax({
            method: "GET",
            url: '/api/influencers/' + id + '/tags/' + tag
        }).done(function (data) {
            // if(data.success)
                $('#js-tag-' + tag).remove();
        });
    })

    $('#js-add-tags').on('click', function(evt) {
        evt.preventDefault();

        $('#modal-add-tags').modal('toggle');
    })

    $('#js-save-tags').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-tags');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/tags',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-add-tags').modal('toggle');
            }
        });
    })


    $('#js-store-social').on('click', function(evt) {
        evt.preventDefault();
        
        var type = $('#modal-store-title').text();
        var username = $('#modal-store-username').val();
        
        var id = $(this).data('id');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/storeSocial',
            data: {
                type: type,
                username: username
            }
        }).done(function (data) {
            // if(data.success) {
                window.location.reload(false);
                $('#modal-store').modal('toggle');
            // }
        });

    })


    $('#js-update-social').on('click', function(evt) {
        evt.preventDefault();
        
        var type = $('#modal-update-title').text();
        var username = $('#modal-update-username').val();
        
        var id = $(this).data('id');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/updateSocial',
            data: {
                type: type,
                username: username
            }
        }).done(function (data) {
            // if(data.success) {
                window.location.reload(false);
                $('#modal-update').modal('toggle');
            // }
        });

    })


    

    

    $('[data-js-delete-social-channel]').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('js-delete-social-channel');
        
        $.ajax({
            method: "POST",
            url: '/api/influencers/social-channel/' + id,
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
            }
        });
    })

    $('#js-update-youtube').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-youtube');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/social-channel/youtube',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-instagram').modal('toggle');
            }
        });
    })

   

    $('#js-save-page').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-page');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/facebook-page',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-select-pages').modal('toggle');
            }
        });
    })

    $('.js-delete-interest').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var interest = $(this).data('interest');

        $.ajax({
            method: "GET",
            url: '/api/influencers/' + id + '/interests/' + interest
        }).done(function (data) {
            if(data.success)
                $('#js-interest-' + interest).remove();
        });
    })

    $('#js-add-interests').on('click', function(evt) {
        evt.preventDefault();

        $('#modal-add-interests').modal('toggle');
    })

    $('#js-save-interests').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var form = $('#js-form-interests');

        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/interests',
            data: form.serialize()
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-add-interests').modal('toggle');
            }
        });
    })

    $('#js-add-rates').on('click', function(evt) {
        evt.preventDefault();

        $('#modal-add-rates').modal('toggle');
    })

    $('#js-save-rate').on('click', function(evt) {
        evt.preventDefault();
        var id = $(this).data('id');
        $.ajax({
            method: "POST",
            url: '/api/influencers/' + id + '/rates',
            data: {
                type: $("#rates-select :selected").data('rate_type'),
                activity_id: $("#rates-select :selected").val(),
                platform_id: $("#social-select :selected").val(),
                duration: $("#duration-select :selected").val(),
                amount: $("#rate-amount").val(),
                currency: $("#currency-select :selected").val(),
                rate_showcase: $("#rate-showcase").val(),
                note: $("#rate-note").val(),
            }  
        }).done(function (data) {
            if(data.success) {
                window.location.reload(false);
                $('#modal-add-rates').modal('toggle');
            }
        });
    })

    $('.js-delete-activity').on('click', function(evt) {
        evt.preventDefault();
        var id = $(this).data('id');
        var rate = $(this).data('rate');

        $.ajax({
            method: "POST",
            url: '/api/influencers/delete_activity',
            data: {
                influencer_id: id,
                rate_id: rate,
            }  
        }).done(function (data) {
            if(data.success) {
                $('#js-activity-' + rate).remove();
                if ($("#table-activities tbody").length == 0) {
                    $('.rate-activities-container').remove();
                }
            }
        });
    })


    $('.js-delete-service').on('click', function(evt) {
        evt.preventDefault();
        var id = $(this).data('id');
        var rate = $(this).data('rate');

        $.ajax({
            method: "POST",
            url: '/api/influencers/delete_service',
            data: {
                influencer_id: id,
                rate_id: rate,
            }  
        }).done(function (data) {
            if(data.success) {
                $('#js-service-' + rate).remove();

                if ($("#table-services tbody").length == 0) {
                    $('.rate-services-container').remove();
                }
            }
        });
    })

    $('.js-delete-attachment').on('click', function(evt) {
        evt.preventDefault();

        var id = $(this).data('id');
        var attachment = $(this).data('attachment');

        $.ajax({
            method: "GET",
            url: '/api/influencers/' + id + '/attachments/' + attachment
        }).done(function (data) {
            if(data.success)
                $('#js-attachment-' + attachment).remove();
        });
    })

    $('#js-upload-attachments').on('hide.bs.modal', function (event) {
        window.location.reload(false);
    });

    $('#js-upload-showcase').on('click', function (event) {
        var dataType = $(this).data('type');
        $('#js-attachment-file-type').val(dataType);
        console.log($('#js-attachment-file-type').val())
    });

    $('#js-upload-files').on('click', function (event) {
        var dataType = $(this).data('type');
        $('#js-attachment-file-type').val(dataType);
        console.log($('#js-attachment-file-type').val())
    });

    $('body').on('click', '[data-delete-showcase]', function () {
        var id = $(this).data('id');
        var attachment = $(this).data('attachment');

        $.ajax({
            method: "GET",
            url: '/api/influencers/' + id + '/attachments/' + attachment
        }).done(function (data) {
            if(data.success)
                $('#js-showcase-' + attachment).remove();
        });
    });

    $('body').on('change', '[data-caption]', function () {

        var id = $(this).data('id');
        var showcase = $(this).data('caption');
        var caption = $(this).val();

        $.ajax({
            method: "PUT",
            url: '/api/influencers/' + id + '/attachments/' + showcase,
            data: {caption : caption}
        }).done(function (data) {
            if(data.success)
                console.log('aaaaaa');
        });
    });

    $('body').on('change', '[data-url]', function () {

        var id = $(this).data('id');
        var showcase = $(this).data('url');
        var url = $(this).val();

        $.ajax({
            method: "PUT",
            url: '/api/influencers/' + id + '/attachments/' + showcase,
            data: {url : url}
        }).done(function (data) {
        });
    });

    $('#js-input-brand-tags').selectize({
        valueField: 'name',
        labelField: 'name',
        searchField: ['name'],

        create: function (input, callback) {
            return {"id":input,"name":input};
        },
        render: {
            option: function (item, escape) {
                return '<div>' + escape(item.name) + '</div>';
            }
        },
        load: function (query, callback) {
            if (!query.length) return callback();
            return callback();
        },
        onInitialize: function() {
            var existingOptions = JSON.parse(this.$input.attr('data-brands'));
            var self = this;
            if(Object.prototype.toString.call( existingOptions ) === "[object Array]") {
                existingOptions.forEach( function (existingOption) {
                    self.addOption(existingOption);
                });
            }
            else if (typeof existingOptions === 'object') {
                self.addOption(existingOptions);
            }

            var selectedOptions = JSON.parse(this.$input.attr('data-selected-options'));
            if(Object.prototype.toString.call( selectedOptions ) === "[object Array]") {
                selectedOptions.forEach( function (selectedOption) {
                    self.addItem(selectedOption[self.settings.valueField]);
                });
            }
            else if (typeof selectedOptions === 'object') {
                self.addItem(selectedOptions[self.settings.valueField]);
            }
        },
        onChange: function(value, item) {
            var id = this.$input.attr('data-id');

            $.ajax({
                method: "PUT",
                url: '/api/influencers/' + id + '/brand-tags',
                data: { tags:value }
            }).done(function (data) {

            });
        }
    });

    // $('.autonumber').autoNumeric('init');

    var input = document.getElementById("js-mobile-photo-sel");

    if(input != null) {
        input.addEventListener("change", function (evt) {
            console.log('onchange')
            var i = 0, len = this.files.length, img, reader, file;

            for (; i < len; i++) {
                file = this.files[i];

                if (!!file.type.match(/image.*/)) {
                    if (window.FileReader) {
                        reader = new FileReader();
                        reader.onloadend = function (e) {
                            showUploadedItem(e.target.result, 'profile_picture');
                        };
                        reader.readAsDataURL(file);
                    }
                    if (formdata) {
                        formdata.append("mobile_thumb[]", file);
                    }
                }
            }

        }, false);
    }

    var input2 = document.getElementById("js-profile-photo-sel");

    if(input2 != null) {
        input2.addEventListener("change", function (evt) {

            var i = 0, len = this.files.length, img, reader, file;

            for (; i < len; i++) {
                file = this.files[i];

                if (!!file.type.match(/image.*/)) {
                    if (window.FileReader) {
                        reader = new FileReader();
                        reader.onloadend = function (e) {
                            showUploadedItem(e.target.result, 'profile_picture');
                        };
                        reader.readAsDataURL(file);
                    }
                }
            }

        }, false);
    }

    $('.image-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-fade',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0, 1]
        }
    });

});

function showUploadedItem(source,type) {
    $('#mobile-cropper').attr('src', source);
    $('#modal-upload-photo').modal('toggle');
    $("#getDataURL").attr('data-type',type);
}

function remove_rate(val) {
    $('#rate_' + val).remove();
}

function initSelectBox(input, apiRoute) {
    $.ajax({
        method: "GET",
        url: apiRoute
    }).done(function (data) {
        var $select = $(input).selectize({
            maxItems: null,
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            options: data,
            plugins: ['remove_button'],
            delimiter: ',',
            persist: false,
            onChange: function (value) {
                currentPage = 0;
                searchInfluencers();
            }
        });
    });
}

function show_side_menu() {
    $('#left_drawer').show();
    $("#left_drawer").animate({right: "0"});
    $('#right_drawer_empty').show();
    $('body, html').css('overflowY', 'hidden');
}

function hide_side_menu() {
    $("#left_drawer").animate({right: "-100%"});
    $('#left_drawer').hide('fast');
    $('#right_drawer_empty').hide();
    $('body, html').css('overflowY', 'auto');
}

$("#modal-upload-photo").on("shown.bs.modal", function () {
    $(function () {
        var $cropper = $("#mobile-cropper"),
            $dataX = $("#dataX"),
            $dataY = $("#dataY"),
            $dataHeight = $("#dataHeight"),
            $dataWidth = $("#dataWidth"),
            $dataRotate = $("#dataRotate"),
            console = window.console || {log: $.noop},
            cropper;

        $cropper.cropper({
            aspectRatio: 1 / 1,
            data: {
                x: 420,
                y: 50,
                width: 200,
                height: 200
            },
            preview: ".preview",

            done: function (data) {
                $dataX.val(data.x);
                $dataY.val(data.y);
                $dataHeight.val(data.height);
                $dataWidth.val(data.width);
                $dataRotate.val(data.rotate);
            },
        });

        cropper = $cropper.data("cropper");

        $("#js-save-photo").click(function () {
            var dataURL = $cropper.cropper("getDataURL", "image/jpeg");
            var id = $(this).attr('data-id');
            var params = {photo: dataURL};

            $.ajax({
                type: "POST",
                data: params,
                url: "/api/influencers/" + id + '/photo',
                success: function (content) {
                    $('#myModal').modal('hide');
                    window.location.reload(false);
                }
            });

        });
    });
})

