var currentPage = 0;

function getFeatured() {
    $.ajax({
        method: "GET",
        url: '/influencers/featured',
        dataType: 'json'
    }).done(function (data) {
        $("#influencerFeaturedItems").append(data.html);
        window.innerWidth < 992 ? initFeaturedCarousel() : null;
        $("#influencerFeaturedParent").slideDown(300);
    });
}

function searchInfluencers(clearList) {
    $(".nextBtn").show();
    $(".nextBtn").html('<i class="la la-refresh la-spin"></i>');
    clearList === true ? $("#influencerListing").html('') : '';

    $.ajax({
        method: "GET",
        url: '/influencers/search?' + getFilters(),
        dataType: 'json'
    }).done(function (data) {
        currentPage = data.currentPage;
        currentPage > 1 ? $("#influencerListing").append(data.html) : $("#influencerListing").html(data.html);
        data.hasMore ? $('.nextBtn').show() : $('.nextBtn').hide();
        $("#js-total-records").html(data.totalRecords);
        $(".nextBtn").html('Load more');
    });
}


function setDataIndexMessageModal(influencer_id){
    $('#message-influencer-id').val(influencer_id);
}





function getFilters() {
    var params = $('form#filter').serialize(),
        nextPage = currentPage + 1;

    params += '&page=' + nextPage;

    return params;
}

function initFeaturedCarousel () {

    if (window.jQuery('#influencerFeaturedItems').length > 0 && typeof window.jQuery('#influencerFeaturedItems').data('owl.carousel') === 'undefined') {
        var owl = window.jQuery('#influencerFeaturedItems');
        owl.find('.col-xs-6').removeClass('col-xs-6').addClass('col-xs-12')
        owl.addClass('owl-carousel')
        owl.owlCarousel({
            stagePadding: 0,
            smartSpeed: 450,
            loop: true,
            autoplay: false,
            slideBy: 2,
            autoplayTimeout: false,
            items: 2,
            margin: 15
        })
    }
}


function closeIndexMessageModal(){
    $('#js-msg-container').hide();
    $('#js-form-message').show().trigger('reset');
    $('#js-send-message-index').html('SEND').show();
}

function closeRecommendInfluencerModal(){
    $('#js-recommend-container').hide();
    $('#js-form-recommend').show().trigger('reset');
    $('#js-send-recommend-influencer').html('SEND').show();
}


function reinitializeCounter(){
    $('[data-counter]').each(function () {
        var time = $(this).data('counter') || 3000;

        $(this).prop('Counter',0).animate({
            Counter: $(this).text()
        }, {
            duration: time,
            easing: 'swing',
            step: function (now) {
                $(this).text(Math.ceil(now).toLocaleString());
            }
        });
    });
}


$(document).ready(function () {

    $(".list-group-item-location").on("click", function (evt) {
        evt.preventDefault();
        $('#location_title').text($(this).data('country_name'));
        $('#country_counter').text($(this).data('country_count'));

        reinitializeCounter();
    });

    $(".location_title_container").on("click", function (evt) {
        evt.preventDefault();
        evt.stopPropagation();

        if($('#locationCollapse ul').hasClass('open')){
            $('#locationCollapse ul').removeClass('open').css("height", '0px');
            $('#location_arrow').css({'transform' : 'rotate('+ 0 +'deg)'});
        }else{
            $('#locationCollapse ul').addClass('open').css("height", '300px');
            $('#location_arrow').css({'transform' : 'rotate('+ 180 +'deg)'});
        }
    });
    


    $("#js-send-recommend-influencer").on("click", function (evt) {
        evt.preventDefault();
        var btn = $(this);
        btn.html('<i class="fa fa-refresh fa-spin"></i>');
        var form = $('#js-form-recommend');
        $.ajax({
            method: "POST",
            url: '/api/send-message-recommend',
            data: form.serialize(),
            success: function () {
                $('#js-recommend-container').html('<i style="font-size: 48px; margin-bottom: 15px; color: #5cb85c;" class="fa fa-check-circle-o" aria-hidden="true"></i><br>Thanks! Your message has ben sent.');
                $('#js-recommend-container').show();
                form.hide();
                $('#js-send-recommend-influencer').hide();
            },
            error: function () {
                $('#js-recommend-container').html('<div class="alert alert-danger">Please, check the required fields.</div>');
                btn.html('SEND');
            }
        });
    });



    $("#js-send-message-index").on("click", function (evt) {
        evt.preventDefault();
        var btn = $(this);
        btn.html('<i class="fa fa-refresh fa-spin"></i>');
        var form = $('#js-form-message');
        $.ajax({
            method: "POST",
            url: '/api/send-message',
            data: form.serialize(),
            success: function () {
                
                $('#js-msg-container').html('<i style="font-size: 48px; margin-bottom: 15px; color: #5cb85c;" class="fa fa-check-circle-o" aria-hidden="true"></i><br>Thanks! Your message has ben sent.');
                $('#js-msg-container').show();
                form.hide();
                $('#js-send-message-index').hide();
            },
            error: function () {
                $('#js-msg-container').html('<div class="alert alert-danger">Please, check the required fields.</div>');
                btn.html('SEND');
            }
        });
    });



    $("form#filter").on("submit", function (e) {
        e.preventDefault();
    });

    $("form#filter input, form#filter select").on("change", function (e) {
        currentPage = 0;

        var search = $('form#filter input[name="search"]').val();
        if (typeof search !== "undefined")
            window.history.pushState({}, "", "?term=" + search)

        searchInfluencers(true);
    });

    $(".nextBtn").on("click", function (e) {
        searchInfluencers();
    });

    getFeatured();
    searchInfluencers();
});
